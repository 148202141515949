<template>
  <v-container fluid class="pt-6 mt-6">
    <Account />
  </v-container>
</template>

<script>
export default {
  name: "AccountPage",
  components: {
    Account: () => import("~cp/Account/Account"),
  },
};
</script>

<style></style>
